import React, { useState } from "react";

import FoodAndDrink from "../../images/foodAndDrink.svg";

import retail from "../../images/retail.svg";

import services from "../../images/services.svg";

import spaAndSalon from "../../images/spaAndSalon.svg";

import healthAndFitness from "../../images/healthAndFitness.svg";

import { useNavigate, Outlet, Link } from "react-router-dom";

import axios from "../../utils/axios";

import Home from "../../images/corporate.jpg";

import Partnership from "../../images/Partnership.jpg";

import Family from "../../images/Family-icon.jpg";

import Rule from "../../images/Rule-of-Use.jpg";

import ArrowUp from "../../images/arrowUp.svg";


import useWindowDimensions from "../../utils/customHooks/useWindowDimensions";

import { useContext } from "react";

import { SessionContext } from "../../components/UserContext";


// eslint-disable-next-line react-hooks/rules-of-hooks


const TrendingFooter = () => {

  const { width } = useWindowDimensions();

  const [showSocial, setShowSocial] = React.useState(false);

  const [data, setData] = React.useState([]);

  const { state, updateState } = useContext(SessionContext);

  const [showMore, setShowMore] = useState(true);

  const navigate = useNavigate();

  React.useEffect(() => {

    FetchData()

  }, []);

  function FetchData() {

    axios

      .get("categories")

      .then((response) => {

        setData(response.data.data);

        updateState({ categories: response.data.data });

        ////console.log(response, "---------------->");

      })

      .catch((error) => {

        console.error("There was an error!", error);

      });

  }

  function show() {

    setShowMore(!showMore);

  }

  return (

    <>

      {width > 800 && (

        <h4

          style={{

            color: "#686C6E",

            marginBottom: 0,

            width: "90%",

            alignSelf: "center",

            marginTop: 5,

          }}

        >

          Categories

        </h4>

      )}

      <div className="categoriesDiv">

        {data.map((item, i) => (

          <div

            key={i}

            style={{

              height: width < 800 ? '100px' : '5.5vw',

              width: width < 800 ? "70px" : '5.5vw',

            }}

          >

            <a

              onClick={() => {

                if (item.id == 8) {

                  setShowSocial(!showSocial);

                  return;

                }

                if (item.id == 7) {

                  navigate("category/", {

                    state: { id: 1, name: item.name, offer_id: 4 },

                  });

                  return;

                }

                if (item.id == 9) {

                  window.open(

                    "https://foodstreetpk.com/download?ref=Vouchapp",

                    "_blank",

                    "noreferrer"

                  );

                  return;

                }

                navigate("category/", {

                  state: { id: item.id, name: item.name, offer_id: 1 },

                });

              }}

              style={{ textDecoration: "none" }}

            >

              <div

                style={{

                  height: 100,

                  display: "flex",

                  alignItems: "center",

                  justifyContent: "center",

                  flexDirection: "column",

                }}

              >

                <img src={item.retail} alt="" className="categoriesImages" />

                <p

                  style={{

                    fontSize: "11px",

                    textAlign: "center",

                    color: "gray",

                    textDecoration: "none",

                  }}

                >

                  {item.name}

                </p>

              </div>

            </a>

          </div>

        ))}

        <div

          style={{

            position: "fixed",

            height: "100vh",

            width: "100vw",

            backgroundColor: "#00000069",

            zIndex: 99,

            top: 0,

            left: 0,

            display: showSocial ? "block" : "none",

          }}

          onClick={() => setShowSocial(false)}

        >

          <div

            style={{

              height: "auto",

              width: "50px",

              padding: 10,

              position: "fixed",

              top: "40vh",

              right: 0,

              zIndex: 99,

              backgroundColor: "#eee",

              borderRadius: "10px 0 0 10px",

              boxShadow: "-1px 5px 9px 2px #00000069",

            }}

          >

            <a href={state.appConstants.whatsapp_link}>

              <img

                src="/images/whatsapp.png"

                style={{ width: "50px", height: "50px", margin: "5px 0" }}

              />

            </a>

            <a href="https://sandbox.vouch365.xyz/uploads/slides/header/snapchat_slide.jpg">

              <img

                src="/images/snapchat.png"

                style={{ margin: "5px 0", width: "50px", height: "50px" }}

              />

            </a>

            <a href="https://instagram.com/vouch365">

              <img

                src="/images/instagram.png"

                style={{ width: "50px", height: "50px", margin: "5px 0" }}

              />

            </a>

            <a href="https://twitter.com/vouch365">

              <img

                src="/images/twitter.png"

                style={{ width: "50px", height: "50px", margin: "5px 0" }}

              />

            </a>

          </div>

        </div>

      </div>

      <div

        onClick={show}

        style={{

          width: "90%",

          alignSelf: "center",

        }}

      >

        {width < 800 ? (

          <div

            style={{ justifyContent: "center", display: "flex", marginTop: 10 }}

          >

            <span> {showMore ? "Show less" : "Show more"}</span>

            <img

              src={ArrowUp}

              alt="Image 1"

              style={{

                height: "15px",

                width: "15px",

                marginLeft: "5px",

                marginTop: "2px",

                transform: showMore ? null : "rotate(180deg)",

              }}

            />

          </div>

        ) : (

          <h4

            style={{

              color: "#686C6E",

              marginBottom: 0,

              marginTop: 50

            }}

          >

            Explore

          </h4>

        )}

      </div>

      {showMore && (

        <div

          style={{

            display: "flex",

            marginTop: "15px",

            flexWrap: "wrap",

            width: "90%",

            alignSelf: "center",

            columnGap: width < 800 ? "7%" : "2.3%",

          }}

        >

          <a href="https://vouch365.com/business/" style={{ borderColor: '#d6d6d6', border: '0.5px #d6d6d6 solid', padding: 5, borderRadius: 10, paddingInline: 13, display: 'flex', flexDirection: 'column', fontSize: '10px', alignItems: 'center', justifyContent: 'center', rowGap: '3px', boxShadow: '5px 5px 5px #d6d6d6' }}>

            <img

              src={Home}

              alt="Image 1"

              style={{

                height: width < 800 ? "13vw" : "50px",

                width: width < 800 ? "13vw" : "50px",

              }}

            />

            Corporate

          </a>

          {/* <img

            src={Family}

            alt="Image 2"

            style={{

              height: width < 800 ? "18vw" : "80px",

              width: width < 800 ? "18vw" : "80px",

            }}

          /> */}

          <a href="https://vouch365.com/contact-us/" style={{ borderColor: '#d6d6d6', border: '0.5px #d6d6d6 solid', padding: 5, borderRadius: 10, paddingInline: 10, display: 'flex', flexDirection: 'column', fontSize: '10px', alignItems: 'center', justifyContent: 'center', rowGap: '3px', boxShadow: '5px 5px 5px #d6d6d6' }}>

            <img

              src={Partnership}

              alt="Image 3"

              style={{

                height: width < 800 ? "13vw" : "50px",

                width: width < 800 ? "13vw" : "50px",

              }}

            />

            Partnership

          </a>

          <a href="https://vouch365.com/rules-of-use/" style={{ borderColor: '#d6d6d6', border: '0.5px #d6d6d6 solid', padding: 5, borderRadius: 10, paddingInline: 8, display: 'flex', flexDirection: 'column', fontSize: '10px', alignItems: 'center', justifyContent: 'center', rowGap: '3px', boxShadow: '5px 5px 5px #d6d6d6' }}>

            <img

              src={Rule}

              alt="Image 4"

              style={{

                height: width < 800 ? "13vw" : "50px",

                width: width < 800 ? "13vw" : "50px",

              }}

            />

            Rules Of Use

          </a>

        </div>

      )}

    </>

  );

};


export default TrendingFooter;
