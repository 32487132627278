import "./index.css";
import React from "react";
import useWindowDimensions from "../../utils/customHooks/useWindowDimensions";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "../../utils/axios";
import { useContext, useState, useEffect, useRef } from "react";
import { SessionContext } from "../../components/UserContext";
import { toast } from "react-toastify";
import JSEncrypt from "jsencrypt";
import { motion } from "framer-motion"

function Splash() {
  const { width } = useWindowDimensions();
  const [decodedArr, setDecodedArr] = useState({});
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const dataFetchedRef = useRef(false);

  const [searchParams] = useSearchParams();
  const { state, updateState } = useContext(SessionContext);

  function handleLogin(info) {
    if (isChecked) return;
    setIsChecked(true);
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };
    const login = "login/vouch365";
    const data = {
      cnic: info.cnic,
      phone: info.account_no,
    };
    axios
      .post(login, data, headers)
      .then((response) => {
        if (response.data.status === true) {
          var usrInfo = response.data.data.user;
          var constants = response.data.data.user.appConstants;
          toast.success("You have successfully logged in!");
          updateState({
            user: usrInfo,
            loggedIn: true,
            token: response.data.data.token,
            appConstants: constants,
          });
          navigate("/");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        const msg =
          "Dear Customer, to use this functionality, please buy the Vouch 365 Package first from the Packages section.";
        toast.error(msg);
        document.getElementById("error_msg").inner = msg;
        console.error("There was an error!", error);
      });
  }

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    let encodedParam = searchParams.get("q");
    //if encodedparam is empty string and with no whitespaces  use string isnull, show toast.error and return
    if (encodedParam == null || encodedParam == "") {
      const msg =
        "Dear Customer, to use this functionality, please buy the Vouch 365 Package first from the Packages section.";
      toast.error(msg);
      document.getElementById("error_msg").innerHTML = msg;
      return;
    }

    try {
      const privateKey =
        "MIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCSN5fn0iGvwcraXY5h93KwnhOaA0QrtzxiXC6RFTEl00jAYZfcfSvLeglcQxzePgT8+/y/4ZwaSTwEMEYAQ8384mFLoeeFUUZdAF9TxChYQrLvlG1kehOi0ZzUq0jGcuL6jH5fD5DHSZ3sZJS3adLd3rmt7q2sWzL85oertNfQevokLBUordtwjopjJ/1csZOH+2gp7HBq1nOdreMCZFG0K2KZhkHh+ZybYl5KtRBp9TjuCCCDySZsz/ZnzjGYrwYa42KH6I/c6SB+NQGeqbLxM8+qBmE2LBFxT/uFRVxX2faROOdwfDVfBZYPChmn3e7pdrCrBFa4sMvhqznG+7LVAgMBAAECggEAJhxrcbcppKAEeapNBGf08ugg/9G+IQ7fpFuWn6ZAazWJDuvhI01U7vJeIjgz/BF6R15+4JyzjX5tMEJM3KDPvgxbg9UtfWhTyMXiEvlb8rVf4mZzpO7U02mDUUNb8q3bT6bzAWATQn6DtSv6P1xL5By7lQRTLEuRHK4RysWWQvaCI9x7H9cZYKAFU8bfilAft4F3Bm4yTMvQrXQuFkCow0NQBuAKxaTXFZ0CXqDP+sEHCZig9FPqxDnuMNgYqeNokfj6kyS6YJpvmOKjga9anKSQb8+4tipc9d7iHnoqYa5+9sGRnCcoP1dXmisGO9oiCh0mCtr6aZLW+4SrNSDS+QKBgQD/F3YVw45kdkMIY8whptEOYbrWFQhdVdXABA2gVsMNOC147/rrppSQ8lAnYmmSifKx3idd+wjZ5yAKkkrnOmO6+lBHxcnz1gDSHWz86jxBB0Nu2D2sMyK/Z2uhBdkS6RliF3ijbdg5UmB9e/lf4vhKrw1K7qRmCUZ0GoIehCxjqwKBgQCSvOIiGVj+q4E4Yvx+gaOVnDVh+fTwPQuHnE+MweOF06l1kuI0P0wJft4fHT1V1mdFK/9qAmYX8oJLxCd13Aeyg34Vz5aCSkZUJJ8wbj9m4j+NBiywfNSzaurMQVCE129fjeo49FT4NJM/dPaF7rvkAdWwEamvVe1RHluCpCHDfwKBgE4kdbv9B+WmhKpRR/sXCvuI3J8wGjvdS3lixHnVmjK2fteJTChcAy7Ty9IEx6poL7xk/VJA0FlP//S9E8z5iguBGo6h/FeBuwailPx1FLgm7XVLMLlLgN15ZPMSw4iTd38hCYem4pHMtlo6XA4PjVfzf/LwX9QSC08Nv8ZxXte5AoGAd5cBJZFK+qOEORRTfDbjv3cD+sngWjsmPmuiWbFbYSJUJcKEINjFe6euF+WYq3CF0dY934P8+N9oWZ+QGXotu8t24wfAPoeSMdl2892mRo92A9VYbq4OG/uzSKyiluGNn27lyW04IuGWcE0+7YDYGMd2BH0gsglx1H7NTfe+nLkCgYBipzyTDVxTGCfCGaTvNXUNvCTlkhj8B3piRziXM6KDjXPoQ9hY7qO0uP+7cpU4DPKvWVz7/Bl/MBlo41hW9MHwcFf/HfaC6jFfCWXg1u0MBfDGt+19hthK+0QZZXPrToXepdIcMH6PjrdDLdyIKkdXho/u1bvIKjEQgMBBPylImA==";
      var decrypt = new JSEncrypt();
      decrypt.setPrivateKey(privateKey);

      const pubKey =
        "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAkjeX59Ihr8HK2l2OYfdysJ4TmgNEK7c8YlwukRUxJdNIwGGX3H0ry3oJXEMc3j4E/Pv8v+GcGkk8BDBGAEPN/OJhS6HnhVFGXQBfU8QoWEKy75RtZHoTotGc1KtIxnLi+ox+Xw+Qx0md7GSUt2nS3d65re6trFsy/OaHq7TX0Hr6JCwVKK3bcI6KYyf9XLGTh/toKexwatZzna3jAmRRtCtimYZB4fmcm2JeSrUQafU47gggg8kmbM/2Z84xmK8GGuNih+iP3OkgfjUBnqmy8TPPqgZhNiwRcU/7hUVcV9n2kTjncHw1XwWWDwoZp93u6XawqwRWuLDL4as5xvuy1QIDAQAB";
      var test = {
        cnic: "4212145878965",
        phone: "03213853365",
      };

      var enc = new JSEncrypt();
      enc.setPublicKey(pubKey);
      ////console.log(test);
      ////console.log("Encrypted: " +  encodeURIComponent(enc.encrypt(JSON.stringify(test))));
      var result = decrypt.decrypt(decodeURI(encodedParam));

      const paramsArray = result.split("&"); // Split into key-value pairs
      const jsonObject = {};

      paramsArray.forEach((param) => {
        const [key, value] = param.split("=");
        jsonObject[key] = value;
      });

      //var info = JSON.parse(result);
      handleLogin(jsonObject);
    } catch (e) {
      //console.log(e);
      const msg =
        "Dear Customer, to use this functionality, please buy the Vouch 365 Package first from the Packages section.";
      toast.error(msg);
      document.getElementById("error_msg").innerHTML = msg;
    }
  }, []);

  return (
    <motion.div
      initial={{ y: 300, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 2 }}>
      <div className="model">
        <div className="model content">
          <div className="container">
            <div className="pic">
              <img src="/images/logo.png" alt="logo" />
            </div>
            <h2 style={{ margin: 0 }}>Welcome to Vouch365</h2>
            <sub>
              <b>Celebrate the journey with us!</b>
            </sub>
            <br />
            <br />
            <br />
            <br />
            <img
              src="/images/intro.jpg"
              alt="splash"
              className="SplashImage"
            // style={{ width: width }}
            />
            <br />
            <br />
            <sub id="error_msg">
              Please wait, while we are verifying your request...
            </sub>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Splash;
