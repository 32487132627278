import React from "react";
import { useContext } from "react";
import { SessionContext } from "../UserContext";

const HeaderOptions = () => {
  var arr = [
    { name: "Not a Member", bg: "#686C6E" },
//    { name: "Purchase", bg: "#EA5457" },
  //  { name: "Activate", bg: "#EA5457" },
  ];

  const { state } = useContext(SessionContext);

  if (state.loggedIn) {
    if (state.user.membership.id == 1) {
      arr = [
        { name: "Free Member", bg: "teal" },
    //    { name: "Purchase", bg: "#EA5457", link: "/shop", target: "_self" },
      //  { name: "Activate", bg: "#EA5457", link: "/activate", target: "_self" },
      ];
    } else if (state.user.membership.id == 2) {
      arr = [
        {
          name: state.user.membership.name.toUpperCase(),
          bg: "gold",
          link: "#",
          target: "_self",
        },
       // { name: "Activate", bg: "#EA5457", link: "/activate", target: "_self" },
       // { name: "Upgrade", bg: "#EA5457", link: "/shop", target: "_self" },
      ];
    } else if (state.user.membership.id == 3) {
      arr = [
        {
          name: state.user.membership.name,
          bg: "gold",
          link: "#",
          target: "_self",
        },
      ];
    }
  }
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDireaction: "row",
          width: "90%",
          alignSelf: "center",
          height: "30px",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        {arr.map((item, i) => (
          <a
            key={i}
            href={item.link}
            target=""
            style={{ textDecoration: "none" }}
          >
            <div
              style={{
                textAlign: "center",
                backgroundColor: item.bg,
                marginRight: 5,
                borderRadius: "10px",
              }}
            >
              <p
                style={{
                  fontWeight: "400",
                  fontSize: "10px",
                  lineHeight: "1px",
                  padding: "10px",
                  color: "white",
                }}
              >
                {item.name}
              </p>
            </div>
          </a>
        ))}
      </div>
      <p
        style={{
          color: "#686C6E",
          margin: "0px 0px 20px 0",
          fontSize: "15px",
          marginTop: "12px",
          width: "90%",
          alignSelf: "center",
        }}
      >
        {" "}
        {state.loggedIn
          ? state.appConstants.landing_subtext
          : "Are you ready to start saving?"}{" "}
      </p>
    </>
  );
};

export default HeaderOptions;
